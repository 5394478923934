<template>
    <div v-if="location != null">
        <v-alert v-model="location.blockMissingPayment" type="error">
            <v-row align="center">
                <v-col class="grow">Your account has been suspended due to missing payments.</v-col>
                <v-col class="shrink">
                    <v-btn depressed style="text-transform:none" @click="paymentDialog = true">Make payment</v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
                <v-card flat>
                    <v-card-title>
                        <h2 class="headline">{{ location.name }}</h2>
                    </v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item class="px-0" two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ location.adminContactName }}</v-list-item-title>
                                    <v-list-item-subtitle>Administrator</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon>mdi-account-box</v-icon>
                                </v-list-item-icon>
                            </v-list-item>

                            <v-list-item class="px-0" :three-line="location.addressLine2 != null && location.addressLine2 != ''" :two-line="location.addressLine2 == null || location.addressLine2 == ''">
                                <v-list-item-content>
                                    <v-list-item-title>{{ location.addressLine1 }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="location.addressLine2 != null && location.addressLine2 != ''">{{ location.addressLine2 }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ location.city }} {{ location.state }}, {{ location.zipcode }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon>mdi-map-marker</v-icon>
                                </v-list-item-icon>
                            </v-list-item>

                            <v-list-item class="px-0" two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ location.totalUnits }}</v-list-item-title>
                                    <v-list-item-subtitle>Total units</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon>mdi-home</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6">
                <v-card flat>
                    <v-card-title>Billing</v-card-title>
                    <v-card-subtitle>This is your community billing statement</v-card-subtitle>
                    <v-card-text v-if="billing != null">
                        <v-row>
                            <v-col cols="6">
                                <h2>Balance</h2>
                            </v-col>
                            <v-col cols="6">
                                <h2 class="text-right error--text">{{ formatMoney( billing.balance ) }}</h2>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                Payments behind
                            </v-col>
                            <v-col cols="6">
                                <div class="text-right">{{ billing.paymentsBehind }}</div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                Next due date
                            </v-col>
                            <v-col cols="6">
                                <div class="text-right">{{ billing.nextDueDate }}</div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                Payment frequency
                            </v-col>
                            <v-col cols="6">
                                <div class="text-right">{{ billing.frequency }}</div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                Payment Amount
                            </v-col>
                            <v-col cols="6">
                                <div class="text-right">{{ formatMoney( billing.monthlyPayment ) }}</div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                Renew date
                            </v-col>
                            <v-col cols="6">
                                <div class="text-right">{{ billing.renewDate }}</div>
                            </v-col>
                        </v-row>

                        <v-btn depressed block @click="paymentDialog = true" class="mt-3">
                            Manage
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card flat>
                    <v-card-title>Payment Information</v-card-title>
                    <v-card-subtitle>Configure the payment service you'll be using to collect your community fees</v-card-subtitle>
                    <v-card-text>

                        <v-tabs v-model="tab">
                            <v-tab>Stripe</v-tab>
                            <!-- <v-tab>Paypal</v-tab> -->
                            <v-tab>ATH Movil</v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-card flat tile>
                                    <v-card-title><v-img src="../assets/stripe_logo.svg" :max-width="200" contain></v-img></v-card-title>
                                    <v-card-subtitle>Allow your community members to make payments using any major credit card</v-card-subtitle>
                                    <v-card-text>
                                        <v-text-field label="Public key" v-model="stripePublicKey"></v-text-field>
                                        <v-text-field label="Secret key" v-model="stripeSecretKey"></v-text-field>
                                        <v-checkbox label="Enable" v-model="stripeEnabled"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <!-- <v-tab-item>
                                <v-card flat tile>
                                    <v-card-title><v-img src="../assets/paypal.svg" :max-width="200" contain></v-img></v-card-title>
                                    <v-card-subtitle>Allow your community members to submit payments with their Paypal account</v-card-subtitle>
                                    <v-card-text>
                                        <v-text-field label="Client ID" v-model="paypalClientId"></v-text-field>
                                        <v-text-field label="Client secret" v-model="paypalClientSecret"></v-text-field>
                                        <v-checkbox label="Enable" v-model="paypalEnabled"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item> -->
                            <v-tab-item>
                                <v-card flat tile>
                                    <v-card-title><v-img src="../assets/athmovil.png" :max-width="200" contain></v-img></v-card-title>
                                    <v-card-subtitle>Accept payment through the ATH Movil service</v-card-subtitle>
                                    <v-card-text>
                                        <v-text-field label="Public token" v-model="athPublicToken"></v-text-field>
                                        <v-text-field label="Private token" v-model="athPrivateToken"></v-text-field>
                                        <v-checkbox label="Enable" v-model="athEnabled"></v-checkbox>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>

                        <v-row>
                            <v-col cols="12">
                                <div>
                                    <p class="caption">* Payment service provider fees may apply</p>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div  class="text-right">
                                    <v-btn color="primary" elevation="0" :loading="loading" @click="updatePaymentInfo">Save</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="paymentDialog" persistent width="800">
            <v-card>
                <v-card-title>
                    Payments

                    <v-spacer></v-spacer>
                    <v-btn icon @click="paymentDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>Submit payments or configure your automatic payments</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="7">
                            <div class="subtitle-1">Available payments</div>
                            <v-simple-table fixed-header height="400">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><v-icon :color="payment.applyTo.length > 0 ? 'indigo darken-4' : ''" @click="toggleSelectAll">{{ icon }}</v-icon></th>
                                            <th class="text-left">Name</th>
                                            <th class="text-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="p in billing.availablePayments" :key="p.id">
                                            <td><v-icon :color="isSelected(p) ? 'primary' : ''" @click="toggleItem(p)">{{ isSelected(p) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></td>
                                            <td>{{ p.dueDateMonthYear }}</td>
                                            <td class="text-right">{{ toMoney( p.amount ) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="5">
                            <div class="subtitle-1">Payment methods</div>

                            <div v-show="billing.cards.length > 0">
                                <template v-for="card in billing.cards">
                                    <v-list-item :key="card.id" class="px-0">
                                        <v-list-item-avatar tile>
                                            <img :src="getCardBrandImg(card.brand)" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>᛫᛫᛫᛫ ᛫᛫᛫᛫ ᛫᛫᛫᛫ {{ card.last4 }}</v-list-item-title>
                                            <v-list-item-subtitle>exp {{ card.exp_month }}/{{ card.exp_year }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon color="success" v-show="isMethodSelected(card)">mdi-check</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>

                                <v-btn block depressed class="mt-3" @click="newCardForm = true" v-show="!newCardForm"><v-icon left>mdi-plus</v-icon> Add new card</v-btn>
                            </div>
                            <div v-show="billing.cards.length == 0 || newCardForm">
                                <div class="mt-3">Add a new credit card</div>
                                <stripe-element-card ref="stripeCard" :pk="location.stripePK" @token="tokenCreated" />
                                <v-btn block depressed @click="createCard" :loading="addingCard" class="mt-3" color="primary">Add card</v-btn>
                                <v-btn block depressed @click="newCardForm = false" class="mt-1" v-show="billing.cards.length > 0">Cancel</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed block @click="processPayment" color="primary" :loading="processingPayment" 
                        :disabled="payment.applyTo.length == 0 || payment.method == null">
                        PAY {{ toMoney( totalPayment ) }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
    data: () => ({
        tab                 : null,
        stripePublicKey     : '',
        stripeSecretKey     : '',
        stripeEnabled       : false,
        paypalClientId      : '',
        paypalClientSecret  : '',
        paypalEnabled       : false,
        athPublicToken      : '',
        athPrivateToken     : '',
        athEnabled          : false,
        loading             : false,

        billing             : null,

        paymentDialog       : false,
        addingCard          : false,
        newCardForm         : false,
        payment             : {
            date: new Date().toISOString().substr(0, 10),
            method: null,
            confirmation: '',
            applyTo: [],
            amount: 0,
        },
        processingPayment   : false,
    }),
    computed: {
        ...mapGetters({
            location: 'auth/location'
        }),
        totalAmount() {
            let total = 0;
            this.billing.availablePayments.forEach(p => {
                total += parseFloat( p.amount );
            });

            return total;
        },
        totalPayment() {
            let total = 0;
            this.payment.applyTo.forEach(p => {
                total += parseFloat( p.amount );
            });
            
            return total;
        },
        allPaymentsSelected() {
            return this.payment.applyTo.length === this.billing.availablePayments.length;
        },
        icon() {
            if (this.allPaymentsSelected) return 'mdi-close-box';
            if (this.payment.applyTo.length > 0 && this.payment.applyTo.length < this.billing.availablePayments.length) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.locationLoaded = false;
                let res = await api.get('/admin/location');
                let resBilling = await api.get('/admin/location/billing');
                this.locationLoaded = true;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }
                
                this.stripePublicKey        = res.data.data.stripePK;
                this.stripeSecretKey        = res.data.data.stripeSK;
                this.stripeEnabled          = res.data.data.stripeActive;
                this.paypalEnabled          = res.data.data.paypalActive;
                this.athPublicToken         = res.data.data.athmovilPublicToken;
                this.athPrivateToken        = res.data.data.athmovilPrivateToken;
                this.athEnabled             = res.data.data.athmovilActive;

                if( !resBilling.data.status ) {
                    this.mxShowMessage( resBilling.data.message, resBilling.data.error ? 'error' : 'warning' );
                    return;
                }

                this.billing = resBilling.data.data;
                if( this.billing.cards.length == 1 ) {
                    this.payment.method = this.billing.cards[0];
                }
            }
            catch(error) {
                this.locationLoaded = true;
                this.mxShowMessage( error, 'error' );
            }
        },
        async updatePaymentInfo() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.put('/admin/location/payment-info', {
                    stripePK        : this.stripePublicKey,
                    stripeSK        : this.stripeSecretKey,
                    stripeEnabled   : this.stripeEnabled,
                    athPublicToken  : this.athPublicToken,
                    athPrivateToken : this.athPrivateToken,
                    athEnabled      : this.athEnabled
                });

                this.loading = false;
                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.mxShowMessage( 'Payment information saved', 'success' );
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
                this.loading = false;
            }
        },
        createCard() {
            this.$refs.stripeCard.submit();
        },
        async tokenCreated( token ) {
            let api = new API();

            try {
                this.addingCard = true;
                let res = await api.post('/admin/location/add-card', {
                    token: token.id
                });
                this.addingCard = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                // add new card to the list
                this.billing.cards.push( res.data.data );
                this.newCardForm = false;
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
                this.addingCard = false;
            }
        },
        getCardBrandImg( brand ) {
            switch( brand.toLowerCase() ) {
                case 'visa':                return require('@/assets/images/visa.svg');
                case 'mastercard':          return require('@/assets/images/mastercard.svg');
                case 'discover':            return require('@/assets/images/discover.svg');
                case 'american express':    return require('@/assets/images/amex.svg');
                default:                    return '';
            }
        },
        toMoney( amount ) {
            return _st.toMoney( amount );
        },
        toggleSelectAll() {
            this.$nextTick(() => {
                if( this.allPaymentsSelected ) {
                    this.payment.applyTo = [];
                } else {
                    this.payment.applyTo = this.billing.availablePayments.slice();
                }
            });
        },
        toggleItem(item) {
            if( !this.isSelected(item) ) 
                this.payment.applyTo.push(item);
            else
                this.payment.applyTo.splice( this.payment.applyTo.indexOf(item), 1);
        },
        isSelected(item) {
            let found = this.payment.applyTo.find(p => {
                return p === item;
            });

            return found != undefined && found != null;
        },
        isMethodSelected(card) {
            let found = this.billing.cards.find(p => {
                return p === card;
            });

            return found != undefined && found != null;
        },
        async processPayment() {
            let api = new API();

            try {
                this.processingPayment = true;
                let res = await api.post('/admin/location/process-payment', {
                    card        : this.payment.method.id,
                    payments    : _st.extractProp( this.payment.applyTo, 'id' )
                });
                this.processingPayment = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.billing = res.data.data;
                this.paymentDialog = false;
                this.mxShowMessage( 'Payment applied', 'success' );
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
                this.processingPayment = false;
            }
        }
    },
    components: {
        StripeElementCard
    }
}
</script>

<style scoped>

</style>
<style>
.v-dialog .v-btn .v-btn__content {
    text-transform: none;
    font-weight: 400;
}
</style>